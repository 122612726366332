import { Stats } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TStatsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'stats' }
>;

const StatsConnected: React.FC<TStatsConnectedProps> = props => {
  const { stats = [] } = props;

  return <Stats stats={stats} />;
};

export default StatsConnected;
