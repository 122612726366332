import React from 'react';

import { Container } from '../../sections/Container';

export type TStatsProps = {
  stats: { label: string; value: number }[];
};

const Stats: React.FC<TStatsProps> = props => {
  const { stats } = props;

  return (
    <Container>
      <div className='flex flex-wrap justify-center gap-x-4 gap-y-6 lg:gap-x-6 2xl:gap-x-5'>
        {stats.map((stat, index) => (
          <div
            key={index}
            className='flex min-w-[6.25rem] flex-col items-center gap-3 text-7xl font-semibold leading-snug text-interface-1000 lg:min-w-[7.25rem] lg:gap-4 lg:text-8xl lg:leading-tight 2xl:min-w-[11.5rem] 2xl:text-9xl'
          >
            {stat.value}
            <span className='text-xs font-medium leading-relaxed text-interface-800 lg:text-sm 2xl:text-lg'>
              {stat.label}
            </span>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Stats;
